
.members-list {
    td {
        vertical-align: middle;
    }

    .avatar {
        text-align: center;
    }

    .job-position {
        font-style: italic;
        margin-top: 5px;
        font-size: 10pt;
        color: #999;
    }
}
