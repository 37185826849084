//@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';

@import "variables.bootstrap";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/bootstrap";

body {
    background-color: rgb(239, 243, 250);
}

header {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 10px;
}

.menu-section {
    margin-bottom: 35px;

    .title {
        color: #999;
        margin-bottom: 5px;
    }

    ul {
        padding-left: 0;

        li {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;
            line-height: 36px;

            a {
                font-size: 14pt;
                color: #444850FF;
            }
        }
    }
}


.nav5 {
    margin-left: 15px;
}

.wising-editor-container {
    border: 1px #eee solid;
}

.page-container {
    background-color: #ffffff;
    border: 1px #ddd solid;
    box-shadow: rgba(65, 69, 88, 0.1) 0 7px 14px 0, rgba(0, 0, 0, 0.07) 0 3px 6px 0;
    border-radius: 5px;

    .content {
        margin-top: 30px;
        padding-bottom: 30px;
    }

    .header {
        .breadcrumbs {
            padding: 18px 25px 18px 25px;
            background-color: rgba(0, 0, 21, 0.03);
            border-bottom: 1px #EBEDF3 solid;
            ul {
                padding: 0;
                margin: 0;

                li {
                    &:first-child {
                        margin-left: 0 !important;
                        border-left: none !important;
                        padding: 0 25px 0 0 !important;
                    }
                    border-left: 1px #ddd solid;
                    font-size: 11pt;
                    color: #666;
                    list-style-type: none;
                    padding: 0 25px 0 25px;
                    margin: 0;
                    display: inline-block;
                    a {
                    }
                }
                .current {
                    font-style: italic;
                }
            }
        }

        .info {
            display: flex;
            padding: 25px 25px 25px 25px;

            .caption {
                flex: 1;

                .title {
                    font-size: 22pt;
                    margin-bottom: 10px;
                }

                .description {
                    font-size: 12pt;
                    color: #666;
                }
            }

            .right-controls {
                text-align: right;
                margin-left: 25px;
            }
        }


        .nav {
            .nav-item:last-of-type {
                border-right: none !important;
            }

            .nav-item {
                padding-top: 10px;
                padding-bottom: 10px;
                border-right: 1px #ddd solid;

                a.active {
                    color: #666;
                }
            }
        }

    }

}


.btn-add-data {
    @extend .btn, .btn-success;
}
.btn-edit {
    @extend .btn;
    background-color: #00aff0;
    color: #ffffff;
    padding: 5px 11px 5px 11px;
    box-shadow: 0 0 1px #999;
}


.btn-auth {
    @extend .btn;

    padding: 10px 70px 10px 70px !important;
    background-color: #FFC951;
    font-size: 14pt;
    color: #444;
    font-weight: 500;

    &:hover {
        box-shadow: 0 0 10px #FFC951;
        border: 1px #f5bd45 solid;
    }
}

.intent-header {
    z-index: 100;
    opacity: .97;
    top: 0;
    border-bottom: 2px #eee solid;
    position: sticky;
    background-color: #f9f9f9;
    padding: 15px 30px 15px 30px;

    .nav-link {
        &.active {
            text-decoration: none;
        }
    }
}
