.content-editor-container {

    padding: 25px;

    .condition-item {
        &.deleted {
            background-color: #fdf5f5;
        }

        padding: 15px;
        margin-bottom: 10px;
        border-bottom: 1px #eee solid;
    }

}
