
.admin-header {
    margin: 25px 10px 25px 0;
    display: flex;
    align-items: center;

    .current-user {
        margin-right: 15px;
        flex-grow: 1;
        font-size: 13pt;

        span {
            padding-right: 8px;
        }
        a {
            cursor: pointer;
        }

        text-align: right;
    }
}
