body {
    background-color: #F1F3F4 !important;
    background-image: url(bg-pattern.png);
}

html, body {
    height: 100%
}

#root, .boord-wrapper {
    min-height: 100%;
}


.boord-wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
}


.logo {
    position: fixed;
    top: 35px;
    left: 50px;

    @media (max-width: 1000px) {
        position: static;
        margin-left: 35px;
        margin-bottom: 35px;
    }

    max-width: 250px;
    height: 80px;

    img {
        object-fit: contain;
        max-height: 80px;
        max-width: 250px;
    }
}

.condition-item {
    label {
        font-size: 16pt;
        font-weight: 400;
    }

    .field {
        margin-top: 8px;
    }
}

.projects-list-container {
    padding-top: 15px;
}

.menu-in-content-container {
    margin-left: 75px;
    margin-top: 25px;
    display: none;
}

.content-menu-left-side {
    position: fixed;
    left: 0;
    top: 140px;
    bottom: 0;
    width: 340px;
    padding-left: 50px;
    padding-right: 15px;
    padding-bottom: 45px;
    overflow: scroll;

    .menu-item {
        max-width: 258px;
    }
}

.menu-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 280px;
    height: 20px;
    background: linear-gradient(rgba(255, 255, 255, 0), #F1F3F4);
}

@media (max-width: 1000px) {
    .content-menu-left-side {
        display: none;
    }
}

@media (min-width: 1000px) {
    padding-left: 290px;
}


.content-menu {
    margin-top: 15px;

    .menu-item {
        position: relative;

        .icon {
            display: inline-block;
            vertical-align: middle;
            background: #3F4254;
            width: 5px;
            height: 5px;
            border-radius: 3px;
            margin-right: 7px;
        }

        &.child-level-1 {
            padding-left: 15px;
        }

        &.child-level-2 {
            padding-left: calc(15px * 2);
        }

        &.child-level-3 {
            padding-left: calc(15px * 3);
        }

        &.child-level-4 {
            padding-left: calc(15px * 4);
        }

        color: #b4b4b4;
        margin-bottom: 12px;
        font-size: 14pt;
        cursor: pointer;

        &.current span {
            padding-bottom: 1px;
            border-bottom: 0.14em #333 solid;
            color: #333;
        }

        &.passed {
            color: #333;
        }
    }
}

.content-area {

    border-radius: 20px;

    display: flex;
    flex-direction: column;
    min-height: 100%;

    background-color: white;
    margin-left: 350px;
    margin-right: 25px;
    padding-bottom: 35px;

    box-shadow: 0 0 8px #d7d2d2;
    @media (max-width: 600px) {
        box-shadow: 0 0 2px #d7d2d2;
        margin-left: 3px;
        margin-top: 3px;
    }
    @media (max-width: 1000px) {
        margin-left: 20px;
        margin-right: 20px;
        min-height: auto;
    }

    font-size: 17pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .content-header {
        padding: 10px 25px 10px 25px;
        border-bottom: 1px #eee solid;
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;

            .title {
                font-weight: 500;
                margin-left: 15px;
                font-size: 20pt;
            }
        }

        @media (min-width: 1001px) {
            .menu-in-content-area-button {
                display: none;
            }
        }
    }

    .content-data {
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
            &.show-menu-in-content {
                .transition-container {
                    display: none;
                }

                .menu-in-content-container {
                    .hint {
                        font-size: 14pt;
                    }

                    .menu-item {
                        font-size: 16pt;
                    }

                    display: block;
                    padding-bottom: 35px;
                }

                .text-data {
                    display: none;
                }
            }
        }

        .text-data {
            letter-spacing: 0.01em;
            flex-grow: 2;
            margin: 45px;
            word-break: break-word;

            @media (max-width: 1000px) {
                margin: 30px;
            }

            p {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }

        .mention-item {
            border-bottom: 0.09em #333 dashed;
            padding-bottom: 1px;
            cursor: pointer;
        }

        .blocked-step {
            margin: 50px auto 0 auto;
            max-width: 550px;

            h3 {
                font-size: 24pt;
            }

            .description {
                margin-top: 25px;
                margin-bottom: 35px;
            }
        }
    }

    .next-step {

    }

    .go-next-button-container {
        margin-top: 10px;
        text-align: center;

        .btn.btn-next-step {
            padding: 10px 70px 10px 70px !important;
            background-color: #FFC951;
            font-size: 18pt;
            color: #444;
            font-weight: 500;

            box-shadow: 0 0 10px #FFC951;
            border: 1px #f5bd45 solid;

            @media (max-width: 1000px) {
                font-size: 16pt;
                padding: 8px 40px 8px 40px !important;
            }

            &:hover {
                box-shadow: 0 0 18px #FFC951;
            }
        }
    }

    .transition-container {
        margin-left: 45px;
        margin-right: 45px;
        @media (max-width: 1000px) {
            margin-left: 45px;
            margin-right: 45px;
        }

        .conditions {
            margin-bottom: 17px;
        }
    }
}

.members-list-container {
    .pagination-control {

        border-bottom: 2px #eee solid;
        padding-bottom: 25px;
        text-align: center;
        margin-top: 20px;

        button {
            &:first-child {
                margin-left: 0 !important;
            }

            color: white;
            box-shadow: 0 0 5px #ddd;
            margin-left: 50px;
        }

    }

    .filters {
        margin-bottom: 35px;
    }
}

.new-content-container {
    margin-top: 25px;

    a {
        cursor: pointer;
        font-size: 13pt;
        color: #52B588 !important;
    }
}

.member-profile {
    font-size: 14pt;
    max-width: 250px;
    text-align: center;
    margin-bottom: 35px;

    .pic {
        text-align: center;

        img {
            border-radius: 50%;
            max-width: 80%;
        }
    }

    .name {
        margin-top: 10px;
        font-weight: bold;
    }

    .position {
        font-style: italic;
        font-size: 13pt;
        color: #999;
        margin-top: 3px;
    }

    .contacts {
        font-size: 12pt;
        margin-top: 15px;
        text-align: left;

        .contact {
            svg {
                margin-right: 5px;
            }

            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0 !important;
            }

            span {
                white-space: nowrap;
            }
        }

        a {
            color: #333;
        }
    }
}

.member-profile-popup {
    .contact {
        margin-bottom: 8px !important;
    }
}

.members-list-container {
    .filters {
        .filter-item {
            label {
                font-size: 16pt;
                color: #666;
                margin-bottom: 3px;
            }
        }
    }

    .search-panel {

    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }
}

.projects-list-container {
    .project-item {
        &:last-child {
            border-bottom: none !important;
        }

        border-bottom: 1px #eee solid;

        .name {
            font-size: 22pt;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .links {
            margin-bottom: 15px;

            .link {

            }

            a {
                padding-left: 8px;
            }
        }

        .description {
            margin-bottom: 15px;
        }

        padding-bottom: 25px;
        margin-bottom: 25px;

        .team {
            .t {
                color: #999999;
                font-weight: 500;
                font-size: 16pt;
            }
        }
    }
}
