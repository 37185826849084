.form-login-container {
    max-width: 535px;
    margin: 8% auto 0 auto;
    padding: 50px 40px 50px 40px;
    background: white;
    text-align: center;
    border-radius: 25px;

    h3 {
        color: #FFBF32;
        font-size: 32pt;
        font-weight: bold;
        margin-bottom: 25px;

    }
}

.check-google-auth-process {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
}

.auth-link-container {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;

    h1 {
        text-align: center;
        font-size: 20pt;
        margin-bottom: 25px;
    }
}
